import {Injectable} from '@angular/core';
import {DomainCheckerService, DomainStatus} from '@process-manager/pm-library';
import {AuthConfig, OAuthService} from 'angular-oauth2-oidc';
import {catchError, firstValueFrom, map, Observable, of} from 'rxjs';
import {filter} from 'rxjs/operators';
import {environment} from '../../../environments/environment';
import {ExtendedDomainStatus} from '../model/extendedDomainStatus';
import {JsonBasedStorage} from '../utils/jsonBasedStorage';
import {createJsonBasedSelectiveStorage} from "../utils/selective-storage";
import {SettingsService} from './settings.service';
import { Platform } from '@ionic/angular';
import { Auth as ssoAuth } from '../../../auth';

@Injectable({
  providedIn: 'root',
})
export class SsoLoginService {
  readonly AUTH_STORAGE_PREFIX = 'pm_auth_';

  constructor(private oAuthService: OAuthService, private settingsService: SettingsService, private domainChecker: DomainCheckerService, private platform: Platform) {
  }

  public checkDomainAndConfigure(domain: string): Observable<DomainStatus | null> {
    return this.domainChecker.getDomainStatus(domain).pipe(map((domainStatus: DomainStatus) => {
      console.debug('Domain status:', JSON.stringify(domainStatus));
      if (domainStatus.type === 'oidc') {
        this.configure(domainStatus as ExtendedDomainStatus);
      }
      return domainStatus;
    }), catchError((err) => {
      console.warn('Configuration failed', err);
      return of(null);
    }));
  }

  public configure(domainStatus?: ExtendedDomainStatus) {
    if (domainStatus) {
      const oidcConfig = domainStatus.oidcConfig;

      if (!oidcConfig) {
        throw Error('OIDC not configured');
      }

      const config: AuthConfig = {
        showDebugInformation: true,
        issuer: oidcConfig.issuer,
        clientId: oidcConfig.clientId,
        responseType: 'code',
        redirectUri: environment.redirectHost + 'callback',
        strictDiscoveryDocumentValidation: false,
        scope: 'openid profile email offline_access ' + oidcConfig.clientId + '/Tree.All',
        decreaseExpirationBySec: 120_000, // Misnamed. It is actually by ms, not seconds
      };
      if(this.platform.is("ios")) {
        const callbackUrl = new URL(environment.redirectHost + 'callback');

        config.openUri = (uri) => ssoAuth.start({
          url: uri,
          callbackHost: callbackUrl.host,
          callbackPath: callbackUrl.pathname.substring(1)
        })
      }

      this.oAuthService.setStorage(createJsonBasedSelectiveStorage(this.AUTH_STORAGE_PREFIX + domainStatus.name));
      this.oAuthService.configure(config);
      
    } else {
      this.oAuthService.setStorage(sessionStorage);
      this.oAuthService.configure({});
    }
  }

  public loadSettings = (domain: string) => firstValueFrom(this.settingsService.loadSettings(domain));

  public userRefreshed$: Observable<void> = this.oAuthService.events.pipe(filter((event) => event.type === 'token_received'), map(() => undefined));

  public async startFlow(domain: string, route: string) {
    await this.oAuthService.loadDiscoveryDocumentAndTryLogin();
    try {
      await this.oAuthService.refreshToken();
    } catch (error) {
      console.debug('Could not begin refresh');
    }

    if(this.oAuthService.hasValidIdToken() && this.oAuthService.hasValidAccessToken()) {
      console.debug('Refresh worked!')
      return true;
    }

    console.debug('No refresh')

    this.oAuthService.initLoginFlow(domain + ';' + route);
    return false;
  }

  public async completeFlow() {
    await this.oAuthService.loadDiscoveryDocumentAndTryLogin();
    if (this.hasValidTokens()) {
      this.oAuthService.setupAutomaticSilentRefresh();
    } else {
      console.debug('No valid tokens after trying login');
      throw Error('Unknown SSO error');
    }
  }

  public hasValidTokens() {
    return this.oAuthService.hasValidIdToken() && this.oAuthService.hasValidAccessToken();
  }

  public getIdentityClaims(): Record<string, any> {
    return this.oAuthService.getIdentityClaims();
  }

  public logOut() {
      this.oAuthService.logOut(true);
  }
}
