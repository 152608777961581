import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {NavController} from '@ionic/angular';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {concatLatestFrom} from '@ngrx/operators';
import {Store} from '@ngrx/store';
import {catchError, exhaustMap, map, Observable, of} from 'rxjs';
import {fromPromise} from 'rxjs/internal/observable/innerFrom';
import {UserMenuActions} from '../../../tabs/components/tabs-toolbar/tabs-toolbar.actions';
import {LoginManagerService} from '../../services/login-manager.service';
import {selectDomainName, selectDomainStatus} from '../domain/domain.selectors';
import {AuthApiActions, AuthEffectsActions, LoginGuardActions} from './auth.actions';
import {selectCurrentUrl, selectSiteSettings} from './auth.selectors';

// noinspection JSUnusedGlobalSymbols
@Injectable()
export class AuthEffects {
  $login = createEffect(() => {
    return this.actions$.pipe(
      ofType(LoginGuardActions.protectedAreaEntered),
      concatLatestFrom(() => [this.store.select(selectDomainStatus)]),
      map(([, domainStatus]) => {
        if (!domainStatus) {
          return AuthEffectsActions.loginFailure({error: new Error('error.unknown')});
        }

        if (this.loginManagerService.hasValidTokens(domainStatus.type)) {
          return AuthEffectsActions.alreadyAuthenticated();
        }

        switch (domainStatus.type) {
          case 'pm':
            return AuthEffectsActions.manualAuthentication();
          case 'oidc':
            return AuthEffectsActions.oidcAuthentication();
        }
      }));
  });

  $oidcAuth = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthEffectsActions.oidcAuthentication),
      concatLatestFrom(() => [this.store.select(selectDomainStatus), this.store.select(selectCurrentUrl)]),
      exhaustMap(([, domainStatus, route]) => {
        if (!domainStatus) {
          return of(AuthEffectsActions.loginFailure({error: new Error('error.unknown')}));
        }

        return fromPromise(this.loginManagerService.loadSettingsOrStartSsoFlow(domainStatus.name, route)).pipe(
          map(result => {
            if (!!result) {
              return AuthApiActions.loginSuccess({
                userClaims: this.loginManagerService.getIdentityClaims(),
                siteSettings: result,
              });
            } else {
              return AuthApiActions.loginWasRedirected();
            }
          }),
        catchError(() => {
          return of(AuthEffectsActions.loginFailure({error: new Error('error.unknown')}))
        }));
      }),
    );
  });

  $manualAuth = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthEffectsActions.manualAuthentication),
      concatLatestFrom(() => this.store.select(selectDomainName)),
      exhaustMap(([, domain]) => {
        if (!domain) {
          return of(AuthEffectsActions.loginFailure({error: new Error('error.unknown')}));
        }

        return this.loginManagerService.loadSettings(domain).pipe(
          map((siteSettings) => AuthApiActions.loginSuccess({
            userClaims: this.loginManagerService.getIdentityClaims(),
            siteSettings,
          })),
          catchError(() => {
            this.router.navigate(['domains', domain, 'login']);
            return of(AuthEffectsActions.redirectedForManualAuthentication());
          }));
      }),
    );
  });

  private updateUserAndSettings = <T>(source: Observable<T>) => {
    return source.pipe(
      concatLatestFrom(() => [this.store.select(selectSiteSettings), this.store.select(selectDomainName)]),
      exhaustMap(([, siteSettings, domain]) => {
        if (!domain) {
          return of(AuthEffectsActions.loginFailure({error: new Error('error.unknown')}));
        } else if (!siteSettings) {
          return this.loginManagerService.loadSettings(domain!).pipe(map(siteSettings => AuthApiActions.loginSuccess({
            userClaims: this.loginManagerService.getIdentityClaims(),
            siteSettings,
          })));
        }

        // We have an ongoing user sessions. We can safely remember the current domain without risking loops.
        this.loginManagerService.setCurrentDomain(domain);

        return of(AuthEffectsActions.userDataLoaded({userData: this.loginManagerService.getIdentityClaims()}));
      }),
    )
  }

  alreadyAuthenticated$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthEffectsActions.alreadyAuthenticated),
      this.updateUserAndSettings
    );
  });

  userUpdated$ = createEffect(() => {
    return this.loginManagerService.userRefreshed$.pipe(this.updateUserAndSettings);
  });

  logout$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserMenuActions.logout),
      concatLatestFrom(() => this.store.select(selectDomainName)),
      map(([, domain]) => {
        if (!!domain) {
          this.loginManagerService.logOut();
        }
        this.navCtrl.navigateBack(['/']).catch(err => console.warn('Could not navigate', err));
        return AuthEffectsActions.logoutComplete();
      }),
    );
  });

  constructor(private actions$: Actions, private store: Store, private loginManagerService: LoginManagerService, private router: Router, private navCtrl: NavController) {
  }
}


