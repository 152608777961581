import { registerPlugin } from '@capacitor/core';

export interface SsoAuthPlugin {
    start(opts: {
        url: string,
        callbackHost: string,
        callbackPath: string
    }): Promise<void>;
}

const Auth = registerPlugin<SsoAuthPlugin>('Auth')

export { Auth };